import React from 'react'
import { useTranslation } from 'react-i18next';
import { useThemeContext } from '../contexts/ThemeContext';
const Loading = () => {
    const { t } = useTranslation();
    const {theme} =useThemeContext();
    return (
        <div className={`${theme===true ?'center':'center-light'}`}>
            <div className="ring"></div>
            <span>{t("loading")}...</span>
      </div>
    )
}

export default Loading