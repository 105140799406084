import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../contexts/LanguageContext";

function Hi() {
  const { t } = useTranslation();
  const {language,} = useLanguageContext();
  return (
    <div className={`hiL`} style={language==="pt" ?{right:'-20px'}:{}}><p >{t("hi")}</p></div>
  )
}

export default Hi