import Muver from "./assets/img/muver.jpg";
import Hinario from "./assets/img/hinario.png";
import Muxiri from "./assets/img/muxiri.png";
import MwanaPwo from "./assets/img/mwanapwo.png";
import Iconspeck from "./assets/img/iconspeck.png";
import ShortName from "./assets/img/shortname.png";
import Covid from "./assets/img/covid.png";
import Theme from "./assets/img/theme.png";
import Mosmmy from "./assets/img/mosmmy.png";
import Spotify from "./assets/img/spotify.png";
const data = [
  {
    id: 4,
    title: "Hinário Adv. do 7º Dia",
    image: Hinario,
    description: "hinario",
    type: "right",
    links: {
      url: "https://hinario-adventista.vercel.app/",
      npm: "",
      apple_store: "",
      play_store: "",
      github: "https://github.com/moser-jose/Hina7",
      whatsapp: "",
    },
    languages:[
      {
        name:"React-Native",
        icon:"react",
        color:"#53C1DE",
        background:"#00d8ff29",
        i:"#53C1DE"
      },

    ]
  },
  {
    id: 2,
    title: "Mwana Pwo - Táxi",
    image: MwanaPwo,
    description: "mwana",
    and: "and",
    plus: "mwana",
    type: "right",
    links: {
      url: "",
      npm: "",
      apple_store: "https://apps.apple.com/app/mwana-pwo-taxi/id6447356865",
      play_store:
        "https://play.google.com/store/apps/details?id=com.mekadir.taximwapwo.app.taxi_mwanapwo_passageiro&pli=1",
      github: "",
      whatsapp: "",
    },
    languages:[
      {
        name:"Flutter",
        icon:"flutter",
        color:"#47C5FB",
        background:"#00B5F829",
        i:"#47C5FB"
      },
      {
        name:"Firebase",
        icon:"firebase",
        color:"#f6820c",
        background:"#fde06829",
        i:"#f6820c"
      }

    ]
  },
  {
    id: 3,
    title: "Spotify-Clone",
    image: Spotify,
    description: "spotify",
    type: "right",
    links: {
      url: "https://moser-jose-spotify-clone.vercel.app/",
      npm: "",
      apple_store: "",
      play_store:"",
      github: "https://github.com/moser-jose/spotify-clone",
      whatsapp: "",
    },
    languages:[
      {

        name:"HTML",
        icon:"html",
        color:"#E44D26 ",
        background:"#f1652963",
        i:"#E44D26"
      },
      {
        name:"Sass",
        icon:"sass",
        color:"#CD6799",
        background:"#CD679929",
        i:"#CD6799"
      },
      {
        name:"React.js",
        icon:"react",
        color:"#53C1DE",
        background:"#00d8ff29",
        i:"#53C1DE"
      },

    ]
  },
  {
    id: 5,
    title: "Muxiri",
    image: Muxiri,
    description: "muxiri",
    type: "left",
    links: {
      url: "",
      npm: "",
      apple_store: "",
      play_store: "",
      github: "",
      whatsapp: "https://wa.me/244923819414",
    },
    languages:[
      {
        name:"HTML",
        icon:"html",
        color:"#E44D26 ",
        background:"#f1652963",
        i:"#E44D26"
      },
      {
        name:"Sass",
        icon:"sass",
        color:"#CD6799",
        background:"#CD679929",
        i:"#CD6799"
      },
      /* {
        name:"PHP",
        icon:"php",
        color:"#b9c5ea",
        background:"#7182b829",
        i:"#b9c5ea"
      }, */
      {
        name:"Laravel",
        icon:"laravel",
        color:"#FF2D20",
        background:"#FF2D2029",
        i:"#FF2D20"
      },

    ]
  },
  {
    id: 1,
    title: "Muver",
    image: Muver,
    description: "muver",
    type: "left",
    links: {
      url: "https://muver.vercel.app/",
      npm: "",
      apple_store: "",
      play_store: "",
      github: "https://github.com/moser-jose/muver",
      whatsapp: "",
    },
    languages:[
      {

        name:"HTML",
        icon:"html",
        color:"#E44D26 ",
        background:"#f1652963",
        i:"#E44D26"
      },
      {
        name:"Sass",
        icon:"sass",
        color:"#CD6799",
        background:"#CD679929",
        i:"#CD6799"
      },
      {
        name:"React.js",
        icon:"react",
        color:"#53C1DE",
        background:"#00d8ff29",
        i:"#53C1DE"
      },

    ]
  },
  {
    id: 6,
    title: "shortname-js",
    image: ShortName,
    description: "shortname",
    type: "left",
    links: {
      url: "",
      npm: "https://www.npmjs.com/package/@mosmmy/shortname-js",
      apple_store: "",
      play_store: "",
      github: "https://github.com/moser-jose/shortname-js",
      whatsapp: "",
    },
    languages:[
      {
        
        name:"TypeScript",
        icon:"typescript",
        color:"#3178c6",
        background:"#3178c629",
        i:"#3178c6"
      }

    ]
  },
  {
    id: 7,
    title: "Iconspeck",
    image: Iconspeck,
    description: "iconspeck",
    type: "right",
    links: {
      url: "https://iconspeck-dev.vercel.app/",
      npm: "https://www.npmjs.com/package/iconspeck",
      apple_store: "",
      play_store: "",
      github: "https://github.com/moser-jose/iconspeck",
      whatsapp: "",
    },
    languages:[
      {

        name:"HTML",
        icon:"html",
        color:"#E44D26 ",
        background:"#f1652963",
        i:"#E44D26"
      },
      {
        name:"Sass",
        icon:"sass",
        color:"#CD6799",
        background:"#CD679929",
        i:"#CD6799"
      },
      {
        name:"React.js",
        icon:"react",
        color:"#53C1DE",
        background:"#00d8ff29",
        i:"#53C1DE"
      },

    ]
  },
  {
    id: 8,
    title: "mosmmy-theme",
    image: Theme,
    description: "mosmmyTheme",
    type: "right",
    links: {
      url: "https://moser-jose.github.io/mosmmy-theme-vscode/",
      npm: "",
      apple_store: "",
      play_store: "",
      github: "https://github.com/moser-jose/mosmmy-theme-vscode",
      whatsapp: "",
    },
    languages:[
      {

        name:"HTML",
        icon:"html",
        color:"#E44D26 ",
        background:"#f1652963",
        i:"#E44D26"
      },
      {
        name:"Sass",
        icon:"sass",
        color:"#CD6799",
        background:"#CD679929",
        i:"#CD6799"
      },
      {
        name:"React.js",
        icon:"react",
        color:"#53C1DE",
        background:"#00d8ff29",
        i:"#53C1DE"
      },

    ]
  },
  {
    id: 9,
    title: "mosmmy-icons",
    image: Mosmmy,
    description: "mosmmyIcons",
    type: "left",
    links: {
      url: "https://marketplace.visualstudio.com/items?itemName=moserjose.mosmmy-icons-vscode",
      npm: "",
      apple_store: "",
      play_store: "",
      github: "https://github.com/moser-jose/mosmmy-icons-vscode",
      whatsapp: "",
    },
    languages:[
      {
        
        name:"Javascript",
        icon:"javascript",
        color:"#F7DF1E",
        background:"#F7DF1E29",
        i:"#F7DF1E"
      }

    ]
  },
  {
    id: 10,
    title: "covid19angola",
    image: Covid,
    description: "covid",
    type: "left",
    links: {
      url: "",
      npm: "",
      apple_store: "",
      play_store: "",
      github: "https://github.com/moser-jose/covid19angola",
      whatsapp: "",
    },
    languages:[
      {
        name:"React-Native",
        icon:"react",
        color:"#53C1DE",
        background:"#00d8ff29",
        i:"#53C1DE"
      },

    ]
  },
];

export default data;
