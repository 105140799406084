import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import Projects from "./Projects";

function Items({ currentItems }) {
  return (
    currentItems &&
    currentItems.map((el) => (
      <Projects
        key={el.id}
        title={el.title}
        image={el.image}
        description={el.description}
        type={el.type}
        links={el.links}
        languages={el.languages}
        and={el.and}
        plus={el.plus}
      />
    ))
  );
}

function Pagination({ data, className, itemsPerPage }) {
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = data.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };
  return (
    <>
      <Items currentItems={currentItems} />
      <ReactPaginate
        className={className}
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
      />
    </>
  );
}

export default Pagination;
