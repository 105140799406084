import React,{useState} from 'react'
import { Link} from 'react-scroll';
import { useTranslation } from "react-i18next";
import Light from '../assets/img/svg/sun-3.svg';
import LightOFF from '../assets/img/svg/moon.svg';
import {useThemeContext} from '../contexts/ThemeContext';
import { useLanguageContext } from '../contexts/LanguageContext';

function Menu({user}) {
    const {theme, themeConfig} = useThemeContext();
    const [menu, setMenu]=useState(false);
    const {language, changeLanguage} = useLanguageContext();
    const {t} = useTranslation();

  return (
      <div className="menu">
        <div className="container">
          <nav>
              <div className='hamlogo'>
                <a href="/" className="logo" rel="noreferrer"> {user.name} </a>
                <div className={menu ===true ? 'hamb men':'hamb'} onClick={()=>setMenu(!menu)}>
                    <span className='bar1'></span>
                    <span className='bar2'></span>
                </div>
              </div>
              
              <div className={`hamburger ${menu ===false ? 'none':''}`}>
                <div className='ham'>
                  <p><span className={`${language==='en'&&'active'}`} onClick={()=>changeLanguage('en')}>EN</span> <small className='language'>|</small> <span className={`${language==='pt'&&'active'}`} onClick={()=>changeLanguage('pt')}>PT</span></p>
                  <div className="theme">
                      { theme && theme===true ? <img src={Light} onClick={()=>themeConfig(false)} alt="Light"/> :<img src={LightOFF} onClick={()=>themeConfig(true)} alt="Light-off"/>}
                  </div>
                </div>
                <div className="menu-lista">
                  <ul>
                    <li><a href="/"><small>01. </small> {t("home")}</a></li>
                    <li><Link onClick={()=>setMenu(false)} className="link" duration={1000} smooth={true} to="sobre"><small>02. </small> {t("about")}</Link></li>
                    <li><Link onClick={()=>setMenu(false)} className="link" duration={1000} smooth={true} to="projetos"><small>03. </small> {t("projects")}</Link></li>
                    <li><Link onClick={()=>setMenu(false)} className="link" duration={1000} smooth={true} to="contatos"><small>04. </small> {t("contacts")}</Link></li>
                  </ul>
                </div>
              </div>
          </nav>
        </div>
      </div>
  )
}

export default Menu