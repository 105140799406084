import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    lng: localStorage.getItem('language'),
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          contacts: "Contacts",
          projects: "Projects",
          about: "About",
          home: "Home",
          loading:"Loading",
          hi:"Hi there!",
          portfolio:"Check out my portfolio",
          about1:'I am a <small>development professional</small> with comprehensive experience in various technologies, including <small>JavaScript</small>, <small>PHP</small>, <small>Node.js</small>, <small>HTML</small>, <small>Sass</small>. Additionally, I have expertise in renowned frameworks such as <small>Laravel</small>, <small>React</small>, <small>React Native</small>, <small>Bootstrap</small> and <small>Tailwind CSS</small>. My ability to work in diverse environments makes me a valuable asset for projects that require a comprehensive and integrated approach. I am committed to delivering innovative and effective solutions, contributing significantly to the success of each project I engage in.. 👨🏽‍💻🎧🫰🏽',
          muver:'Project to view catalogs of currently popular movies and TV series with various information about actors, trailers, directors, and much more.',
          spotify:'This is a project where the Spotify page is cloned to the smallest details using tools like React.js, Sass.',
          mwana:'Mwana Pwo is a mobile application for taxi services, specifically in the Moxico province of Angola, available for use ',
          and:'and',
          hinario:'This is a project for a Mobile App and and website with complete information on the 610 hymns from the Seventh-day Adventist Hymnal.',
          muxiri:'Muxiri is an API and a academic management system that covers academic and financial management for higher education in Angola.',
          shortname: "shortname-js is a JavaScript function designed to shorten names (PT 🇵🇹 | BR 🇧🇷 | AO 🇦🇴) in a simple and user-friendly way.",
          mosmmyTheme:'mosmmy-theme-vscode is a theme package for Visual Studio Code that provides enhanced colors to highlight different code structures.',
          mosmmyIcons:'mosmmy-icons-vscode is a theme consisting of over 200 icons for files and folders in vscode, with over 25K downloads.',
          covid:'covid19angola is a mobile app for tracking Covid-19 statistics in Angola and around the world.',
          notific:'notific is a project for a mobile app for "announcements" in the Seventh-day Adventist Church.',
          iconspeck:'Iconspeck is a collection of over 600 icons for programmers and others to use in their projects, professional websites, and more.',
          intress:'I am interested in opportunities that include problem solving, professional and personal development especially ambitious projects. If you want to know more about me and what I can do, don\'t hesitate to use the contact details below to talk to me.',
          paz:'✌🏾 Peace and ❤️ Love',
          direitos:'All rights reserved.'
        },
      },
      pt: {
        translation: {
          contacts: "Contatos",
          projects: "Projetos",
          about: "Sobre mim",
          home: "Início",
          loading:"A Carregar",
          hi:"Olá!",
          portfolio:"Conheça meu portfólio",
          about1:'Sou um <small>profissional de desenvolvimento</small> com experiência abrangente em várias tecnologias, incluindo <small>JavaScript</small>, <small>PHP</small>, <small>Node.js</small>, <small>HTML</small>. Além disso, domino frameworks renomados como <small>Laravel</small>, <small>React</small>, <small>React Native</small>, <small>Bootstrap</small> e <small>Tailwind CSS</small>. Minhas habilidades abrangem tanto o desenvolvimento front-end quanto o back-end, permitindo-me criar soluções web robustas e aplicativos móveis dinâmicos. Estou comprometido em oferecer soluções inovadoras e eficazes, contribuindo significativamente para o sucesso de cada projeto em que me envolvo.. 👨🏽‍💻🎧🫰🏽',
          muver:'Projeto para ver catálogos de filmes e series mais populares atualmente com várias informações sobre os atores, trailers, diretor e muito mais.',
          spotify:'Esse é um projecto onde é clonado a página do Spotify usando ferramentas como React.js, Sass',
          mwana:'Mwana Pwo é um aplicativo móvel para corridas de táxi especificamente na província do Moxico em Angola, disponível para',
          and:'e',
          hinario:'Esse é um projeto de um App Mobile e sua respetiva página web dos 610 hinos do Hinário Adventista do 7º dia. Com informações completa',
          shortname:'shortname-js é uma função em JavaScript projetada para encurtar nomes (PT 🇵🇹 | BR 🇧🇷 | AO 🇦🇴) de uma forma simples e amigável ao usuário.',
          muxiri:'Muxiri é uma API e um Sistema de Gestão académico completo, desde a gestão académica até a gestão financeira para o Ensino Superior em Angola.',
          mosmmyTheme:'mosmmy-theme-vscode é um pacote de tema para o Visual Studio Code que fornece cores aprimoradas para destacar diferentes estruturas de código.',
          mosmmyIcons:'mosmmy-icons-vscode é um tema de mais de 200 icons para ficheiros e pastas no vscode, com mais de 25K downloads',
          covid:'covid19angola é um App Mobile para as estatísticas do Covid19 em Angola e ao redor do mundo.',
          notific:'notific é um projeto de um aplicativo móvel para `anúncios` na `Igreja Adventista do Sétimo (7º) dia.',
          iconspeck:'Iconspeck é um conjunto de mais de 600 ícones para os programadores e não só utilizarem em seus projetos, sites profissionais, etc.',
          intress:'Estou interessado em oportunidades que incluam resolução de problemas, desenvolvimento professional e pessoal especialmente projetos ambiciosos. Se você quiser saber mais sobre mim e sobre o que eu posso fazer, não hesite em usar os contatos abaixo para poder conversar comigo.',
          paz:'✌🏾 Paz e ❤️ Amor',
          direitos:'Todos direitos reservados'
        },
      },
    },
  });

export default i18n;