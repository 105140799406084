import React from 'react'
import CardProject from './CardProject';

function ProjectRight({title,languages,description,plus,links,and,image}) {
  return (
    <div className="projectos grid-f-2">
        {image != null && (
          <div className="image">
            <img src={image} alt="muver" />
          </div>
        )}
        <CardProject title={title} languages={languages} description={description} and={and} links={links} plus={plus}/>
    </div>
  )
}

export default ProjectRight