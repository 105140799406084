import React from 'react'
import { useTranslation } from 'react-i18next';
import Languages from './Languages'
import { useThemeContext } from '../contexts/ThemeContext';

function CardProject({title,languages,description,plus,links,and}) {
    const { t } = useTranslation();
    const {theme}= useThemeContext();
    return (
        <div className="texto">
            <div className="descricao">
                <h1>{title}</h1>
                <div className="tools">
                <Languages languages={languages}/>
                </div>
                <p>
                {t(description)}{" "}
                {plus === "mwana" && (
                    <>
                    <a href={links.play_store}>Android</a> {t(and)}{" "}
                    <a href={links.apple_store}>iPhone</a>.
                    </>
                )}
                </p>
                <ul>
                {links.github && (
                    <li>
                    <a href={links.github} rel="noreferrer" target="_blank">
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        role="img"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-github"
                        >
                        <title>GitHub</title>
                        <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                        </svg>
                    </a>
                    </li>
                )}
                {links.url && (
                    <li>
                    <a href={links.url} rel="noreferrer" target="_blank">
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        role="img"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-external-link"
                        >
                        <title>External Link</title>
                        <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                        <polyline points="15 3 21 3 21 9"></polyline>
                        <line x1="10" y1="14" x2="21" y2="3"></line>
                        </svg>
                    </a>
                    </li>
                )}
                {links.npm && (
                    <li>
                    <a href={links.npm} rel="noreferrer" target="_blank">
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        >
                        <g data-name="Layer 2">
                            <g data-name="npm">
                            <path d="M18 21H6a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3zM6 5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1z" />
                            <path d="M12 9h4v10h-4z" />
                            </g>
                        </g>
                        </svg>
                    </a>
                    </li>
                )}
                {links.apple_store && (
                    <li>
                    <a href={links.apple_store} rel="noreferrer" target="_blank">
                        <svg
                        strokeWidth="2.5"
                        fill={theme ? "#ccd6f6":"#030a15"}
                        width="20px"
                        height="20px"
                        viewBox="0 0 1920 1920"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                            d="m1380.664 0 5.547 52.865c13.923 131.766-31.47 278.7-121.238 393.146-1.359 1.585-2.944 2.717-4.189 4.302 14.943-.566 29.885-1.698 45.394-.226 67.694 2.83 245.646 28.64 362.016 202.176l31.583 47.205-47.318 31.47c-40.752 25.81-165.839 118.748-164.141 293.077 1.924 220.742 179.876 301.68 200.14 310.17l48.562 20.377-16.3 50.26c-13.472 43.47-52.3 154.746-128.144 267.947-72.335 107.768-162.217 242.024-314.246 244.854-71.769 1.811-117.615-19.81-159.613-38.262-39.96-17.66-74.26-32.828-131.086-32.828-60.11 0-96.448 15.735-138.558 34.073-39.507 17.207-84.335 36.677-146.482 39.28-2.83.114-5.66.114-8.264.114-147.274 0-249.608-150.897-317.755-251.306l-5.187-7.735c-170.414-256.903-265.7-675.19-108.466-953.793 86.598-153.614 244.174-250.627 411.258-253.117 65.09-.906 124.974 19.47 176.594 39.846 8.83-119.88 65.883-231.722 125.087-303.944C1061.89 84.448 1206.448 7.245 1327.573 2.264L1380.664 0Zm-82.75 562.948c-73.694-7.81-144.331 21.282-207.157 46.639-47.432 19.13-88.297 35.545-126.22 35.545-32.375 0-66.335-10.98-101.654-24.791l-5.433-.453-.227-1.698c-7.471-3.056-14.942-6-22.64-9.056C785 588.984 730.55 562.608 676.102 567.25c-127.125 1.924-247.684 76.863-314.586 195.61-144.897 256.627-23.658 647.057 108.787 842.215 70.41 103.805 145.123 206.365 227.76 201.724 40.978-1.698 69.505-14.15 105.843-29.885 46.865-20.49 100.07-43.582 183.725-43.582 80.712 0 131.765 22.527 176.82 42.45 37.242 16.527 68.146 30.451 111.729 28.64 86.372-1.585 146.708-81.731 222.326-194.706 49.582-73.92 81.278-146.708 99.277-195.271-76.637-45.96-220.628-162.443-222.666-389.411-1.698-180.103 97.24-294.662 165.613-352.168-85.353-92.598-195.385-107.88-242.816-109.918Zm-23.093-438.314c-76.524 20.49-158.707 72.449-211.233 136.86l-.113.113c-46.525 56.827-95.994 150.784-101.088 247.457 84.674-12.678 165.273-71.769 213.61-133.01 58.411-74.374 93.39-164.821 98.824-251.42Z"
                            fillRule="evenodd"
                        />
                        </svg>
                    </a>
                    </li>
                )}
                {links.play_store && (
                    <li>
                    <a href={links.play_store} rel="noreferrer" target="_blank">
                        <svg
                        strokeWidth="2.5"
                        fill={theme ? "#ccd6f6":"#030a15"}
                        width="80px"
                        height="19px"
                        viewBox="0 0 1920 1920"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                            d="M535.056 9.512c25.75-17.28 60.987-10.39 78.38 15.586l94.304 141.061c59.859-25.976 123.782-44.61 191.885-50.597a671.282 671.282 0 0 1 314.085 48.226l92.385-138.577c17.167-26.09 52.178-32.979 78.267-15.699 25.976 17.28 32.979 52.404 15.699 78.267l-85.721 128.639c29.251 18.86 57.373 39.415 83.123 63.02 118.474 108.535 183.753 253.324 183.753 407.6v734.107c0 88.206-76.008 160.036-169.41 160.036h-169.409V1920h-112.94v-338.82H790.638V1920h-112.94v-338.82H508.29c-93.401 0-169.41-71.829-169.41-160.035V709.286c0-200.468 107.293-380.268 269.249-488.916L519.583 87.892c-17.393-25.863-10.39-60.987 15.473-78.38Zm424.427 216.392c-16.602 0-33.317.791-50.032 2.26-256.6 22.474-457.632 233.784-457.632 481.122v711.86c0 25.863 25.299 47.095 56.47 47.095h903.518c31.17 0 56.47-21.232 56.47-47.096V687.037c0-122.313-52.179-237.512-147.161-324.363-96.112-88.205-225.766-136.77-361.633-136.77ZM113 1298.832h112.94V734.133H113v564.699Zm1581.156 0h112.94V734.133h-112.94v564.699ZM734.168 474.394c-49.919 0-90.351 40.433-90.351 90.352 0 49.807 40.432 90.352 90.351 90.352 49.92 0 90.352-40.545 90.352-90.352 0-49.92-40.432-90.352-90.352-90.352m451.76 0c-49.92 0-90.353 40.433-90.353 90.352 0 49.807 40.433 90.352 90.352 90.352 49.92 0 90.352-40.545 90.352-90.352 0-49.92-40.432-90.352-90.352-90.352"
                            fillRule="evenodd"
                        />
                        </svg>
                    </a>
                    </li>
                )}
                {links.whatsapp && (
                    <li>
                    <a href={links.whatsapp} rel="noreferrer" target="_blank">
                        <svg
                        version="1.1"
                        id="Icons"
                        fill="transparent"
                        strokeWidth="2.5"
                        viewBox="0 0 32 32"
                        >
                        <path
                            className="st0"
                            d="M17,1C9.3,1,3,7.3,3,15c0,3.4,1.3,6.6,3.3,9L4,31l7.3-3.2C13,28.6,15,29,17,29c7.7,0,14-6.3,14-14S24.7,1,17,1z
                                            "
                        />
                        <g id="Page-1">
                            <g
                            id="Whatsapp"
                            transform="translate(1.000000, 1.000000)"
                            >
                            <path
                                id="Stroke-28"
                                className="st1"
                                d="M23.7,19.1c0.3-0.9,0.3-1.6,0.2-1.8c-0.1-0.2-0.3-0.2-0.7-0.4c-0.4-0.2-2.2-1.1-2.5-1.2
                                    c-0.3-0.1-0.7-0.1-0.9,0.3c-0.4,0.5-0.7,1-1,1.3c-0.2,0.2-0.6,0.3-0.9,0.1c-0.4-0.2-1.6-0.6-3-1.8c-1.1-1-1.8-2.2-2.1-2.6
                                    c-0.2-0.4,0-0.6,0.2-0.8c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.2,0.3-0.3,0.4-0.6c0.1-0.2,0-0.5-0.1-0.7c-0.1-0.2-0.8-2-1.1-2.7
                                    c-0.2-0.6-0.4-0.6-0.8-0.6c-0.1,0-0.3,0-0.4,0c-0.5,0-1,0.1-1.3,0.5C9.9,7.9,9,8.8,9,10.6c0,1.8,1.3,3.6,1.5,3.8
                                    c0.2,0.2,2.6,4,6.3,5.6c2.9,1.2,3.8,1.1,4.4,1C22.2,20.7,23.4,20,23.7,19.1L23.7,19.1z"
                            />
                            </g>
                        </g>
                        </svg>
                    </a>
                    </li>
                )}
                </ul>
            </div>
        </div>
  )
}

export default CardProject