import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';


export const StateContext = createContext();

export const StateThemeContext = ({ children }) => {
    const [theme, setTheme] = useState(true);
    

    const themeConfig = useCallback(t => {
      setTheme(t);
      localStorage.setItem('theme',t);
    },[setTheme])

    useEffect(() => {
        const t=((localStorage.getItem('theme') !== 'false' && localStorage.getItem('theme') !== 'true') ? true : localStorage.getItem('theme') === 'false' ? false:true);
        themeConfig(t);
    }, [themeConfig]);

    return (
        <StateContext.Provider
            value={{ theme, themeConfig }}>
            {children}
        </StateContext.Provider>
    );
};

export const useThemeContext = () => useContext(StateContext);