import React from "react";
import ProjectLeft from "./ProjectLeft";
import ProjectRight from "./ProjectRigth";
function Projects({ title, image, description, type, links, and, plus, languages }) {

  return type === "left" ? (
    <ProjectLeft title={title} image={image} description={description} type={type} links={links} and={and} plus={plus} languages={languages}/>
  ) : (
    type === "right" && (
      <ProjectRight title={title} image={image} description={description} type={type} links={links} and={and} plus={plus} languages={languages}/>
    )
  );
}

export default Projects;
