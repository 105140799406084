import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useTranslation } from "react-i18next";

export const StateContext = createContext();

export const StateLanguageContext = ({ children }) => {
    const [language, setLanguage] = useState('en');
    
    const { t, i18n } = useTranslation();
    const changeLanguage = useCallback(lng => {
      setLanguage(lng);
      localStorage.setItem('language',lng);
      i18n.changeLanguage(lng);
    },[i18n])

    useEffect(() => {
        const l= (localStorage.getItem('language') !== 'en' && localStorage.getItem('language') !== 'pt') ? 'en':localStorage.getItem('language');
        changeLanguage(l);
    },[changeLanguage])

    return (
        <StateContext.Provider
            value={{ language, changeLanguage }}>
            {children}
        </StateContext.Provider>
    );
};

export const useLanguageContext = () => useContext(StateContext);