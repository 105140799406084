import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { userData, followers,following, repos } from '../api'

export const StateContext = createContext();

export const StateGitHubContext = ({ children }) => {
    const [loading, setLoading]=useState(false);
    const [user, setUser]= useState({});
    const [follower, setFollower]= useState([]);
    const [followin, setFollowin]= useState([]);
    const [repositors, setRepositors]= useState([]);

    const getData = useCallback(
        async function (token) {
            try {
                setLoading(false)
                const data= await Promise.all([
                    await userData.get(),
                    await followers.get(),
                    await following.get(),
                    await repos.get(),
                ])
                setUser(data[0].data)
                setFollower(data[1].data)
                setFollowin(data[2].data)
                setRepositors(data[3].data)
                setLoading(true)
            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        },
        [],
    );


    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <StateContext.Provider
            value={{ user, follower,followin, repositors, loading }}>
            {children}
        </StateContext.Provider>
    );
};

export const useGitHubContext = () => useContext(StateContext);