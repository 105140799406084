import React, { memo, useCallback, useEffect, useState } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { format, parseISO } from "date-fns";
import { useGitHubContext } from "../contexts/GitHubContext";
import Loading from "../components/Loading";
import particles_light from "./../particles_light.json";
import particles_moon from "./../particles_moon.json";
import Top from "../components/Top";
import Menu from "../components/Menu";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import data from "../data";
import Pagination from "../components/Pagination";
import { useThemeContext } from "../contexts/ThemeContext";
import Hi from "../assets/img/hi.png";
import HI from "../components/Hi";

function App() {
  const { theme } = useThemeContext();
  const { t } = useTranslation();

  const [scrollPosition, setScrollPosition] = useState(0);
  const particlesInit = useCallback((main) => {
    loadFull(main);
  }, []);
  const { user, follower, followin, repositors, loading } = useGitHubContext();

  const star = () => {
    var s = 0;
    repositors.forEach((el) => {
      s += el.stargazers_count;
    });
    return s;
  };

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };
  const htmlObject = { __html: t("about1") };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return loading ? (
    <div className={`App ${theme === true ? "moon" : "light"}`}>
      <Menu user={user} />
      <main>
        {theme === true ? (
          <Particles
            className="back"
            options={particles_moon}
            init={particlesInit}
          />
        ) : (
          <Particles
            className="back"
            options={particles_light}
            init={particlesInit}
          />
        )}

        <div className="home">
          <div className="pessoal">
            <div className="anim">
              <HI />
              <img className="hi" src={Hi} alt="Moser José" />
            </div>
            <div className="social-media">
              <ul>
                <li>
                  <a
                    href="https://github.com/moser-jose"
                    aria-label="GitHub"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-github"
                    >
                      <title>GitHub</title>
                      <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    rel="noreferrer"
                    href="https://www.instagram.com/mosmmy/"
                    aria-label="Instagram"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-instagram"
                    >
                      <title>Instagram</title>
                      <rect
                        x="2"
                        y="2"
                        width="20"
                        height="20"
                        rx="5"
                        ry="5"
                      ></rect>
                      <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                      <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/mosmmy"
                    aria-label="Twitter"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-twitter"
                    >
                      <title>Twitter</title>
                      <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/moser-jose/"
                    aria-label="Linkedin"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-linkedin"
                    >
                      <title>LinkedIn</title>
                      <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                      <rect x="2" y="9" width="4" height="12"></rect>
                      <circle cx="4" cy="4" r="2"></circle>
                    </svg>
                  </a>
                </li>

                <li>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://youtube.com/@moserando"
                  >
                    <svg
                      viewBox="0 0 45 45"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="3"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="a"
                        d="M43.1124,14.394a5.0056,5.0056,0,0,0-3.5332-3.5332c-2.3145-.8936-24.7326-1.3314-31.2358.0256A5.0059,5.0059,0,0,0,4.81,14.42c-1.0446,4.583-1.1239,14.4914.0256,19.1767A5.006,5.006,0,0,0,8.369,37.13c4.5829,1.0548,26.3712,1.2033,31.2358,0a5.0057,5.0057,0,0,0,3.5332-3.5333C44.2518,28.6037,44.3311,19.31,43.1124,14.394Z"
                      />
                      <path
                        className="a"
                        d="M30.5669,23.9952,20.1208,18.004V29.9863Z"
                      />
                    </svg>
                  </a>
                </li>

                <li>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://tiktok.com/@mosmmy"
                  >
                    <svg
                      viewBox="0 0 41 45"
                      stroke="currentColor"
                      strokeWidth="3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>Tiktok</title>
                      <path d="M21.3583 19.14C15.4696 18.8559 11.3764 20.9554 9.07862 25.4385C5.63194 32.1631 8.48051 43.1666 19.9791 43.1666C31.4776 43.1666 31.8103 32.0555 31.8103 30.8915C31.8103 30.1154 31.8103 25.7765 31.8103 17.8746C34.2697 19.4324 36.3433 20.3701 38.031 20.6877C39.7188 21.0054 40.7918 21.1462 41.2499 21.1101V14.6343C39.6888 14.4461 38.3388 14.0874 37.1999 13.5582C35.4915 12.7643 32.104 10.5612 32.104 7.33214C32.1063 7.34793 32.1063 6.51499 32.104 4.83331H24.9859C24.9648 20.6494 24.9648 29.3354 24.9859 30.8915C25.0177 33.2255 23.207 36.4905 19.5358 36.4905C15.8645 36.4905 14.0538 33.2282 14.0538 31.1239C14.0538 29.8357 14.4962 27.9685 16.3253 26.5858C17.41 25.7659 18.9155 25.4385 21.3583 25.4385C21.3583 24.6829 21.3583 22.5834 21.3583 19.14Z" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>

            <h1>Moser José</h1>
            <h2>
              <span>Software Developer</span>
            </h2>
            {/* <p>{user.description}</p> */}

            <div className="button">
              <Link className="link" duration={1000} smooth={true} to="sobre">
                {t("portfolio")}
              </Link>
            </div>
          </div>
          <div className="git">
            <div className="git-data">
              <div className="git-n">
                <img src={user.owner.avatar_url} alt="" />
                <div>
                  <h1>
                    <span>
                      Moser José <small>({user.name})</small>
                    </span>
                    <a
                      target="_blank"
                      href="https://github.com/moser-jose"
                      rel="noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        width="16"
                        height="16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"
                        ></path>
                      </svg>
                    </a>
                  </h1>
                  <p>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        width="16"
                        height="16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.5 3.5a2 2 0 100 4 2 2 0 000-4zM2 5.5a3.5 3.5 0 115.898 2.549 5.507 5.507 0 013.034 4.084.75.75 0 11-1.482.235 4.001 4.001 0 00-7.9 0 .75.75 0 01-1.482-.236A5.507 5.507 0 013.102 8.05 3.49 3.49 0 012 5.5zM11 4a.75.75 0 100 1.5 1.5 1.5 0 01.666 2.844.75.75 0 00-.416.672v.352a.75.75 0 00.574.73c1.2.289 2.162 1.2 2.522 2.372a.75.75 0 101.434-.44 5.01 5.01 0 00-2.56-3.012A3 3 0 0011 4z"
                        ></path>
                      </svg>
                      <b>{follower.length} </b> seguidores <small></small>{" "}
                      <b>{followin.length}</b> seguindo
                    </span>

                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        width="16"
                        height="16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2 2.5A2.5 2.5 0 014.5 0h8.75a.75.75 0 01.75.75v12.5a.75.75 0 01-.75.75h-2.5a.75.75 0 110-1.5h1.75v-2h-8a1 1 0 00-.714 1.7.75.75 0 01-1.072 1.05A2.495 2.495 0 012 11.5v-9zm10.5-1V9h-8c-.356 0-.694.074-1 .208V2.5a1 1 0 011-1h8zM5 12.25v3.25a.25.25 0 00.4.2l1.45-1.087a.25.25 0 01.3 0L8.6 15.7a.25.25 0 00.4-.2v-3.25a.25.25 0 00-.25-.25h-3.5a.25.25 0 00-.25.25z"
                        ></path>
                      </svg>
                      <b>{repositors.length}</b>
                    </span>

                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        width="16"
                        height="16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 .25a.75.75 0 01.673.418l1.882 3.815 4.21.612a.75.75 0 01.416 1.279l-3.046 2.97.719 4.192a.75.75 0 01-1.088.791L8 12.347l-3.766 1.98a.75.75 0 01-1.088-.79l.72-4.194L.818 6.374a.75.75 0 01.416-1.28l4.21-.611L7.327.668A.75.75 0 018 .25zm0 2.445L6.615 5.5a.75.75 0 01-.564.41l-3.097.45 2.24 2.184a.75.75 0 01.216.664l-.528 3.084 2.769-1.456a.75.75 0 01.698 0l2.77 1.456-.53-3.084a.75.75 0 01.216-.664l2.24-2.183-3.096-.45a.75.75 0 01-.564-.41L8 2.694v.001z"
                        ></path>
                      </svg>
                      <b>{star()}</b>
                    </span>
                  </p>
                </div>
              </div>
              <div className="repositors">
                {repositors.map((val, k) => {
                  return (
                    <div className="repo" key={k}>
                      <h3>
                        <a href={val.svn_url} target="_blank" rel="noreferrer">
                          {val.name}{" "}
                        </a>
                        <small>{val.visibility}</small>
                      </h3>
                      <p>{val.description}</p>
                      <div className="tags">
                        {val.topics.map((top, ke) => {
                          return <span key={ke}>{top}</span>;
                        })}
                      </div>
                      <div className="footer">
                        {val.language && <span>{val.language}</span>}

                        {val.stargazers_count !== 0 && (
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 16"
                              width="16"
                              height="16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 .25a.75.75 0 01.673.418l1.882 3.815 4.21.612a.75.75 0 01.416 1.279l-3.046 2.97.719 4.192a.75.75 0 01-1.088.791L8 12.347l-3.766 1.98a.75.75 0 01-1.088-.79l.72-4.194L.818 6.374a.75.75 0 01.416-1.28l4.21-.611L7.327.668A.75.75 0 018 .25zm0 2.445L6.615 5.5a.75.75 0 01-.564.41l-3.097.45 2.24 2.184a.75.75 0 01.216.664l-.528 3.084 2.769-1.456a.75.75 0 01.698 0l2.77 1.456-.53-3.084a.75.75 0 01.216-.664l2.24-2.183-3.096-.45a.75.75 0 01-.564-.41L8 2.694v.001z"
                              ></path>
                            </svg>
                            {val.stargazers_count}
                          </span>
                        )}

                        {val.forks_count !== 0 && (
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 16"
                              width="16"
                              height="16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M5 3.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm0 2.122a2.25 2.25 0 10-1.5 0v.878A2.25 2.25 0 005.75 8.5h1.5v2.128a2.251 2.251 0 101.5 0V8.5h1.5a2.25 2.25 0 002.25-2.25v-.878a2.25 2.25 0 10-1.5 0v.878a.75.75 0 01-.75.75h-4.5A.75.75 0 015 6.25v-.878zm3.75 7.378a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm3-8.75a.75.75 0 100-1.5.75.75 0 000 1.5z"
                              ></path>
                            </svg>
                            {val.forks_count}
                          </span>
                        )}

                        {val.license && (
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 16"
                              width="16"
                              height="16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8.75.75a.75.75 0 00-1.5 0V2h-.984c-.305 0-.604.08-.869.23l-1.288.737A.25.25 0 013.984 3H1.75a.75.75 0 000 1.5h.428L.066 9.192a.75.75 0 00.154.838l.53-.53-.53.53v.001l.002.002.002.002.006.006.016.015.045.04a3.514 3.514 0 00.686.45A4.492 4.492 0 003 11c.88 0 1.556-.22 2.023-.454a3.515 3.515 0 00.686-.45l.045-.04.016-.015.006-.006.002-.002.001-.002L5.25 9.5l.53.53a.75.75 0 00.154-.838L3.822 4.5h.162c.305 0 .604-.08.869-.23l1.289-.737a.25.25 0 01.124-.033h.984V13h-2.5a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-2.5V3.5h.984a.25.25 0 01.124.033l1.29.736c.264.152.563.231.868.231h.162l-2.112 4.692a.75.75 0 00.154.838l.53-.53-.53.53v.001l.002.002.002.002.006.006.016.015.045.04a3.517 3.517 0 00.686.45A4.492 4.492 0 0013 11c.88 0 1.556-.22 2.023-.454a3.512 3.512 0 00.686-.45l.045-.04.01-.01.006-.005.006-.006.002-.002.001-.002-.529-.531.53.53a.75.75 0 00.154-.838L13.823 4.5h.427a.75.75 0 000-1.5h-2.234a.25.25 0 01-.124-.033l-1.29-.736A1.75 1.75 0 009.735 2H8.75V.75zM1.695 9.227c.285.135.718.273 1.305.273s1.02-.138 1.305-.273L3 6.327l-1.305 2.9zm10 0c.285.135.718.273 1.305.273s1.02-.138 1.305-.273L13 6.327l-1.305 2.9z"
                              ></path>
                            </svg>
                            {val.license.name}
                          </span>
                        )}

                        <span>
                          Atualizado em
                          {format(parseISO(val.updated_at), " dd MMM yy")}{" "}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* <div className="social-media">
            <ul>
              <li>
                <a
                  href="https://github.com/moser-jose"
                  aria-label="GitHub"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    role="img"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-github"
                  >
                    <title>GitHub</title>
                    <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                  </svg>
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  href="https://www.instagram.com/mosmmy/"
                  aria-label="Instagram"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    role="img"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-instagram"
                  >
                    <title>Instagram</title>
                    <rect
                      x="2"
                      y="2"
                      width="20"
                      height="20"
                      rx="5"
                      ry="5"
                    ></rect>
                    <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                    <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/mosmmy"
                  aria-label="Twitter"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    role="img"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-twitter"
                  >
                    <title>Twitter</title>
                    <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/moser-jose/"
                  aria-label="Linkedin"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    role="img"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-linkedin"
                  >
                    <title>LinkedIn</title>
                    <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                    <rect x="2" y="9" width="4" height="12"></rect>
                    <circle cx="4" cy="4" r="2"></circle>
                  </svg>
                </a>
              </li>

              <li>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://youtube.com/@moserando"
                >
                
                  <svg 
                   viewBox="0 0 45 45" 
                   fill="none"
                   stroke="currentColor"
                   strokeWidth="2"
                   xmlns="http://www.w3.org/2000/svg">
                    <path className="a" d="M43.1124,14.394a5.0056,5.0056,0,0,0-3.5332-3.5332c-2.3145-.8936-24.7326-1.3314-31.2358.0256A5.0059,5.0059,0,0,0,4.81,14.42c-1.0446,4.583-1.1239,14.4914.0256,19.1767A5.006,5.006,0,0,0,8.369,37.13c4.5829,1.0548,26.3712,1.2033,31.2358,0a5.0057,5.0057,0,0,0,3.5332-3.5333C44.2518,28.6037,44.3311,19.31,43.1124,14.394Z"/><path className="a" d="M30.5669,23.9952,20.1208,18.004V29.9863Z"/>
                  </svg>  
                </a>
              </li>


              
              <li>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://tiktok.com/@mosmmy"
                >
                  <svg
                    viewBox="0 0 41 45"
                    stroke="currentColor"
                    strokeWidth="1.45"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Tiktok</title>
                    <path d="M21.3583 19.14C15.4696 18.8559 11.3764 20.9554 9.07862 25.4385C5.63194 32.1631 8.48051 43.1666 19.9791 43.1666C31.4776 43.1666 31.8103 32.0555 31.8103 30.8915C31.8103 30.1154 31.8103 25.7765 31.8103 17.8746C34.2697 19.4324 36.3433 20.3701 38.031 20.6877C39.7188 21.0054 40.7918 21.1462 41.2499 21.1101V14.6343C39.6888 14.4461 38.3388 14.0874 37.1999 13.5582C35.4915 12.7643 32.104 10.5612 32.104 7.33214C32.1063 7.34793 32.1063 6.51499 32.104 4.83331H24.9859C24.9648 20.6494 24.9648 29.3354 24.9859 30.8915C25.0177 33.2255 23.207 36.4905 19.5358 36.4905C15.8645 36.4905 14.0538 33.2282 14.0538 31.1239C14.0538 29.8357 14.4962 27.9685 16.3253 26.5858C17.41 25.7659 18.9155 25.4385 21.3583 25.4385C21.3583 24.6829 21.3583 22.5834 21.3583 19.14Z" />
                  </svg>
                </a>
              </li>
            </ul>
          </div> */}
        </div>
        <div className="container">
          <div id="sobre" className="separador">
            <h1>
              <small>02. </small>
              {t("about")}
            </h1>
          </div>
          <div className="sobre grid-2-f">
            {/* <div className="img">
              <img src={user.owner.avatar_url} alt={user.name}></img>
            </div> */}
            <div className="stack">
              <div className="card">
                <div className="image">
                  <img src={user.owner.avatar_url} alt={user.name} />
                </div>
              </div>
            </div>
            <div className="texto">
              <p dangerouslySetInnerHTML={htmlObject}></p>
              {/* <ul>
                <li>html</li>
                <li>css(scss)</li>
                <li>javascript</li>
                <li>node.js</li>
                <li>react.js</li>
                <li>react-native</li>
                <li>typescript</li>
                <li>php</li>
                <li>laravel</li>
              </ul> */}
            </div>
          </div>
          <div id="projetos" className="separador">
            <h1>
              <small>03. </small>
              {t("projects")}
            </h1>
          </div>
          <Pagination className="navigation" data={data} itemsPerPage="5" />
        </div>

        <div className="container">
          <div id="contatos" className="separador">
            <h1>
              <small>04. </small>
              {t("contacts")}
            </h1>
          </div>
          <div className="contatos">
            <div className="img">
              <img src={user.owner.avatar_url} alt={user.name}></img>
            </div>
            <h1>{t("intress")}</h1>
            <div className="social">
              <a
                href="https://github.com/moser-jose"
                aria-label="GitHub"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-github"
                >
                  <title>GitHub</title>
                  <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                </svg>
              </a>
              <a
                rel="noreferrer"
                href="https://www.instagram.com/mosmmy/"
                aria-label="Instagram"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-instagram"
                >
                  <title>Instagram</title>
                  <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                </svg>
              </a>

              <a
                href="https://twitter.com/mosmmy"
                aria-label="Twitter"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-twitter"
                >
                  <title>Twitter</title>
                  <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                </svg>
              </a>

              <a
                href="https://www.linkedin.com/in/moser-jose/"
                aria-label="Linkedin"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-linkedin"
                >
                  <title>LinkedIn</title>
                  <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                  <rect x="2" y="9" width="4" height="12"></rect>
                  <circle cx="4" cy="4" r="2"></circle>
                </svg>
              </a>

              <a
                rel="noreferrer"
                target="_blank"
                href="https://youtube.com/@moserando"
              >
                <svg
                  viewBox="0 0 45 45"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2.2"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="a"
                    d="M43.1124,14.394a5.0056,5.0056,0,0,0-3.5332-3.5332c-2.3145-.8936-24.7326-1.3314-31.2358.0256A5.0059,5.0059,0,0,0,4.81,14.42c-1.0446,4.583-1.1239,14.4914.0256,19.1767A5.006,5.006,0,0,0,8.369,37.13c4.5829,1.0548,26.3712,1.2033,31.2358,0a5.0057,5.0057,0,0,0,3.5332-3.5333C44.2518,28.6037,44.3311,19.31,43.1124,14.394Z"
                  />
                  <path
                    className="a"
                    d="M30.5669,23.9952,20.1208,18.004V29.9863Z"
                  />
                </svg>
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://tiktok.com/@mosmmy"
              >
                <svg
                  viewBox="0 0 41 45"
                  stroke="currentColor"
                  strokeWidth="2.80"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Tiktok</title>
                  <path d="M21.3583 19.14C15.4696 18.8559 11.3764 20.9554 9.07862 25.4385C5.63194 32.1631 8.48051 43.1666 19.9791 43.1666C31.4776 43.1666 31.8103 32.0555 31.8103 30.8915C31.8103 30.1154 31.8103 25.7765 31.8103 17.8746C34.2697 19.4324 36.3433 20.3701 38.031 20.6877C39.7188 21.0054 40.7918 21.1462 41.2499 21.1101V14.6343C39.6888 14.4461 38.3388 14.0874 37.1999 13.5582C35.4915 12.7643 32.104 10.5612 32.104 7.33214C32.1063 7.34793 32.1063 6.51499 32.104 4.83331H24.9859C24.9648 20.6494 24.9648 29.3354 24.9859 30.8915C25.0177 33.2255 23.207 36.4905 19.5358 36.4905C15.8645 36.4905 14.0538 33.2282 14.0538 31.1239C14.0538 29.8357 14.4962 27.9685 16.3253 26.5858C17.41 25.7659 18.9155 25.4385 21.3583 25.4385C21.3583 24.6829 21.3583 22.5834 21.3583 19.14Z" />
                </svg>
              </a>

              <a
                href="https://wa.me/244923819414"
                rel="noreferrer"
                target="_blank"
              >
                <svg
                  fill="#ccd6f6"
                  strokeWidth="2.80"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 56 56 "
                >
                  <title>Whatsapp</title>
                  <g>
                    <g>
                      <path
                        d="M26,0C11.663,0,0,11.663,0,26c0,4.891,1.359,9.639,3.937,13.762C2.91,43.36,1.055,50.166,1.035,50.237
                            c-0.096,0.352,0.007,0.728,0.27,0.981c0.263,0.253,0.643,0.343,0.989,0.237L12.6,48.285C16.637,50.717,21.26,52,26,52
                            c14.337,0,26-11.663,26-26S40.337,0,26,0z M26,50c-4.519,0-8.921-1.263-12.731-3.651c-0.161-0.101-0.346-0.152-0.531-0.152
                            c-0.099,0-0.198,0.015-0.294,0.044l-8.999,2.77c0.661-2.413,1.849-6.729,2.538-9.13c0.08-0.278,0.035-0.578-0.122-0.821
                            C3.335,35.173,2,30.657,2,26C2,12.767,12.767,2,26,2s24,10.767,24,24S39.233,50,26,50z"
                      />
                      <path
                        d="M42.985,32.126c-1.846-1.025-3.418-2.053-4.565-2.803c-0.876-0.572-1.509-0.985-1.973-1.218
                            c-1.297-0.647-2.28-0.19-2.654,0.188c-0.047,0.047-0.089,0.098-0.125,0.152c-1.347,2.021-3.106,3.954-3.621,4.058
                            c-0.595-0.093-3.38-1.676-6.148-3.981c-2.826-2.355-4.604-4.61-4.865-6.146C20.847,20.51,21.5,19.336,21.5,18
                            c0-1.377-3.212-7.126-3.793-7.707c-0.583-0.582-1.896-0.673-3.903-0.273c-0.193,0.039-0.371,0.134-0.511,0.273
                            c-0.243,0.243-5.929,6.04-3.227,13.066c2.966,7.711,10.579,16.674,20.285,18.13c1.103,0.165,2.137,0.247,3.105,0.247
                            c5.71,0,9.08-2.873,10.029-8.572C43.556,32.747,43.355,32.331,42.985,32.126z M30.648,39.511
                            c-10.264-1.539-16.729-11.708-18.715-16.87c-1.97-5.12,1.663-9.685,2.575-10.717c0.742-0.126,1.523-0.179,1.849-0.128
                            c0.681,0.947,3.039,5.402,3.143,6.204c0,0.525-0.171,1.256-2.207,3.293C17.105,21.48,17,21.734,17,22c0,5.236,11.044,12.5,13,12.5
                            c1.701,0,3.919-2.859,5.182-4.722c0.073,0.003,0.196,0.028,0.371,0.116c0.36,0.181,0.984,0.588,1.773,1.104
                            c1.042,0.681,2.426,1.585,4.06,2.522C40.644,37.09,38.57,40.701,30.648,39.511z"
                      />
                    </g>
                  </g>
                </svg>
              </a>
              <a
                href="mailto:moser.jose@outlook.com"
                rel="noreferrer"
                target="_blank"
              >
                <svg
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2.80"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="style=linear">
                    <g id="email">
                      <path
                        id="vector"
                        d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        id="vector_2"
                        d="M18.7698 7.7688L13.2228 12.0551C12.5025 12.6116 11.4973 12.6116 10.777 12.0551L5.22998 7.7688"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </g>
                  </g>
                </svg>
              </a>
            </div>
            <div>
              <p>{t("paz")}</p>
            </div>
          </div>
        </div>

        <footer>
          <small>
            <a href="/">Moser José</a> &copy; {new Date().getFullYear()}.{" "}
            {t("direitos")}
          </small>
        </footer>
        {scrollPosition > 50 && <Top />}
      </main>
    </div>
  ) : (
    <Loading />
  );
}
export default memo(App);
