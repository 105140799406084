import React from 'react'
import CardProject from './CardProject';

function ProjectLeft({title,languages,description,plus,links,and,image}) {
  return (
    <div className="projectos grid-2-f">
      <CardProject 
      title={title} 
      languages={languages} 
      description={description} 
      and={and} links={links} 
      plus={plus}/>
      {image != null && (
        <div className="image">
          <img src={image} alt="muver" />
        </div>
      )}
    </div>
  )
}

export default ProjectLeft