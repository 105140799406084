import axios from "axios";
let config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "*" 
    }
  }
  const apiGithub = axios.create({
    baseURL: 'https://api.github.com/search/users?q=moser-jose',
    config
  });

  const userData = axios.create({
    baseURL: 'https://api.github.com/repos/moser-jose/moser-jose',
    config
  });
  const followers = axios.create({
    baseURL: 'https://api.github.com/users/moser-jose/followers',
    config
  });

  const following = axios.create({
    baseURL: 'https://api.github.com/users/moser-jose/following',
    config
  });

  const repos = axios.create({
    baseURL: 'https://api.github.com/users/moser-jose/repos',
    config
  });

  
export {apiGithub,userData, followers, following, repos};