import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/scss/App.scss';
import "./i18n";
import App from './pages/App';
import { StateLanguageContext } from './contexts/LanguageContext';
import { StateGitHubContext } from './contexts/GitHubContext';
import { StateThemeContext } from './contexts/ThemeContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StateThemeContext>
      <StateLanguageContext>
        <StateGitHubContext>
        <App />
        </StateGitHubContext>
      </StateLanguageContext>
    </StateThemeContext>
  </React.StrictMode>
);